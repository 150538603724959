.why-body{
    width: 100%;
    padding: 2rem 0;
    height: auto;
    background: url('./blur.jpg');
    background-size: cover;
    z-index: 1;
    background-repeat: no-repeat;
}

.why-choose{
    width: 90%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.why-left{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 40%;
    height: auto;
    font-weight: 700;
    background-color: white;
    padding: 3%;
    
}
.why-left h1,h5{
    text-align: center;
    text-transform: uppercase;
    word-spacing: .1rem;
    letter-spacing: .1rem;
}
.why-left ul li{
    text-transform: capitalize ;
}
.why-right{
    width: 50%;
    background-color: black;
    padding: 2%;
    margin:5rem 0;
    height: 20rem;
    border-radius: 6px;
    border: 1px solid white;
    box-shadow: rgba(243, 245, 247, 0.3) 0px 1px 2px 0px, rgba(236, 238, 239, 0.15) 0px 2px 6px 2px,
    rgba(236, 237, 239, 0.3) 0px 1px 2px 0px, rgba(240, 243, 245, 0.15) 0px 2px 6px 2px;
}
.why-card-holder{
    width: 100%;
    background-color: antiquewhite;
    height: 100%;
    border-radius: 5px;
    display: flex;
    overflow: hidden;
    
}
.why-img{
    animation: changeSlide 20s  4s infinite ease;   
}
.why-img img{
    height: 100%;
    width: 33rem;
    top: 0;
    left: 0;
}
@keyframes changeSlide {
        0%{
            transform: translateX(0%);
        }
        10%{
            transform: translateX(-100%);
        }
        20%{
            transform: translateX(-100%);
        }
        30%{
            transform: translateX(-200%);
        }
        40%{
            transform: translateX(-200%);
        }
        50%{
            transform: translateX(-200%);
        }
        60%{
            transform: translateX(-300%);
        }
        70%{
            transform: translateX(-300%);
        }
        80%{
            transform: translateX(-300%);
        }
        90%{
            transform: translateX(0%);
        }
        100%{
            transform: translateX(0%);
        }
  }


  @media screen and (max-width:715px){
    .why-body{
        width: 100%;
        height: auto;
    }
    .why-choose{
        width: 90%;
        display: flex;
        flex-direction:column;
        margin-left: auto;
        margin-right: auto;
    }
    .why-left{
        margin-top: 2rem;
        width: 100%;
    }
    .why-right{
        width: 100%;
    }
}
@media screen and (min-width:1285px){
    .why-right{
        width: 36.5rem;
    }
    .why-card-holder{
        width:33rem ;
    }
    .why-img{
        width: 100%;
    }
}
