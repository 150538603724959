.cont-wrapper{
    background-image: url(../home/home-bgi.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
    width: 100%;
    padding-top: 4rem;
    padding-bottom: 4rem;
    text-transform: capitalize;
}
.cont-container{
    background-color: aliceblue;
    width:70%;
    margin-left: auto;
    margin-right: auto;
    padding: 5%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    border-radius: 7px;
    border: 2px solid black;
    box-shadow: rgba(242, 244, 245, 0.3) 0px 1px 2px 0px, rgba(248, 249, 250, 0.15) 0px 2px 6px 2px,
    rgba(244, 245, 246, 0.3) 0px 1px 2px 0px, rgba(250, 251, 252, 0.15) 0px 2px 6px 2px;
}
.cont-items{
    display: flex;
    gap: 1rem;
    text-decoration: none;
}
.cont-items a{
    text-decoration: none;
    color: rgb(34, 34, 152);
}

.cont-items a:hover{
    text-decoration: none;
    color: rgb(4, 4, 39);
    font-weight: 700;
}

@media screen and (max-width:760px){
    .cont-items{
        display: flex;
        flex-direction: column;
    }
}