.product h3 {
    margin-top: .5rem;
    font-size: 1.2rem;
    letter-spacing: 1.2px;
    color: #4c4c4c;
}
.product img {
    max-width: 80%;
    filter: drop-shadow(1px 1px 3px #a6a6a6);
}
.soldout{
    position: absolute;
    width: 20%;
    right: 1rem;
}
/* ----- Product Section ----- */
.product {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: auto;
    padding: 2.5em 0;
    min-width: 100px;
    background-color: white;
    border-radius: 5px;
    margin-top: 4.5rem;
}
/* ----- Photo Section ----- */
.product__photo {
    width: 100%;
    height: auto;
    
}

.photo-container {
    width: 85%;
    height: 70%;
    border-radius: 6px;
    box-shadow: 4px 4px 25px -2px rgba(0, 0, 0, 0.3);
    margin-left: 5%;
    
}

.photo-main {
    border-radius: 0 0 0 0;
    background-color: #e1e5d9;
    /* background: radial-gradient(#e5f89e, #d2e8a7); */
    
}
.photo-main .controls {
    display: flex;
    justify-content: space-between;
    padding: 0.8em;
    color: #fff;
}
.photo-main .controls i {
    cursor: pointer;
}
.photo-main img {
    align-content: center;
    float: right;
    top: 1em;
    max-width: 230px;
    max-height: 230px;
    filter: saturate(150%) contrast(120%) hue-rotate(10deg) drop-shadow(1px 20px 10px rgba(0, 0, 0, 0.3));
}
.photo-album {
    padding: 0.7em 1em;
    border-radius: 0 0 6px 6px;
    background-color: #fff;
    cursor: pointer;
}
.photo-album ul {
    display: flex;
    justify-content: space-around;
}
.photo-album li {
    float: left;
    width: 55px;
    height: 55px;
    padding: 7px;
    border: 1px solid #a6a6a6;
    border-radius: 3px;
}
/* ----- Informations Section ----- */
.product__info {
    padding: 0.8em 0;
}
.title h1 {
    margin-bottom: 0.1em;
    color: #524a4a;
    font-size: 1.7em;
    font-weight: 900;
}
.title span {
    font-size: 0.7em;
    color: #a6a6a6;
}
.price {
    margin: .8rem 0;
    color: #ff3f40;
    font-size: 1.2em;
}
.price span {
    padding-left: 0.15em;
    font-size: 2rem;
}
.variant {
    overflow: auto;
}
.variant h3 {
    margin-bottom: 1.1em;
}
.variant li {
    float: left;
    width: 35px;
    height: 35px;
    padding: 3px;
    border: 1px solid transparent;
    border-radius: 3px;
    cursor: pointer;
    
}
.variant li:first-child, .variant li:hover {
    border: 1px solid #a6a6a6;
}
.variant li:not(:first-child) {
    margin-left: 0.1em;
}
.description {
    clear: left;
    margin: 2em 0;

}
.description h3 {
    margin-bottom: 1em;
}
.description ul {
    font-size: 0.8em;
    list-style: none;
    margin-left: 1em;
}
.description li {
    text-indent: -0.6em;
    margin-bottom: 0.5em;
}
.prd-label{
    margin-top: 1rem;
    font-size: .8rem;
    size: .8rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.product .buy--btn {
    padding: 1.5em 3.1em;
    border: none;
    border-radius: 7px;
    font-size: 0.8em;
    font-weight: 700;
    letter-spacing: 1.3px;
    color: #fff;
    background-color: #ff3f40;
    box-shadow: 2px 2px 25px -7px #4c4c4c;
    cursor: pointer;
}
.buy--btn:hover{
    background-color: black;
}
.buy--btn:active {
    transform: scale(0.97);
}

@media screen and (max-width:806px){
    .product{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: center;
        justify-content: space-around;
    }
    .product__info{
        margin-left: 1rem;
    }
    .photo-container{
        height: 17rem;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 2rem;
       
    }
}