.abtus-wrapper{
    background-image: url(../home/blur.jpg);
    background-size: cover;
    height: fit-content;
    background-repeat: no-repeat;
    width: 100%;
    padding-top: 3rem;
    padding-bottom: 3rem;
}
.abtus-wrapper h3{
    font-size: 30px;
    text-transform: uppercase;
    word-spacing: .2rem;
}
.abtus-wrapper h2{
    font-size: 35px;
    text-transform: uppercase;
    word-spacing: .2rem;
}
.abtus-item img{
    width: 100%;
    height: 90%;
    border-radius: 5px;
    box-shadow: rgba(48, 48, 49, 0.3) 0px 1px 2px 0px, rgba(48, 48, 49, 0.15) 0px 2px 6px 2px,
    rgba(47, 48, 48, 0.3) 0px 1px 2px 0px, rgba(44, 45, 45, 0.15) 0px 2px 6px 2px;
}
.abtus-container{
    padding: 5%;
    width: 80%;
    background-color: aliceblue;
    margin-left: auto;
    margin-right: auto;
    border-radius: 6px;
    border: 1.5px solid black;
    box-shadow: rgba(231, 233, 235, 0.3) 0px 1px 2px 0px, rgba(243, 244, 246, 0.15) 0px 2px 6px 2px,
    rgba(235, 238, 240, 0.3) 0px 1px 2px 0px, rgba(241, 244, 246, 0.15) 0px 2px 6px 2px;
    display: flex;
    flex-direction: column;
    gap: 5%;
    
}
.abtus-item{
    margin-top: 2rem;
    display: flex;
    justify-content:space-around;
    gap: 2rem;
    margin-bottom: 1rem;
}
.abtus-left{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.abtus-items{
   display: flex;
   flex-direction: column;
   gap: 1rem;
}

@media screen and (max-width:820px) {
    .abtus-item{
        display: flex;
        flex-direction: column;
    }
    
}