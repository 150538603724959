.fc-content img{
    width: 100%; 
    border-radius: 5px;
    padding: 2%;
}
.featured-bgc{
    width: 100%;
    text-align: center;
    margin-top: 3rem;
    background-color: white;
}
.featured-container{
    max-width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4rem;
}
.featured-container h3{
    margin-top: .7rem;
    font-size: 16px;
    color: rgb(115, 111, 111);
}
.foo-con{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}
.fc-fc-btn{
    margin-bottom: 2rem;
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: rgb(34, 33, 33);
    border-radius: 5px;
    border: 1px solid white;
    color: aliceblue;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 13px;
}
.fc-fc-btn:hover{
    cursor: pointer;
    background-color: aliceblue;
    color: black;
    font-size: 15px;
}
.fc-grid{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    justify-items: space-between;
    align-items: center;
    gap: 5%;
}
.foo-con a{
    font-style: none;
    cursor: pointer;
    text-decoration: none;
    color: aliceblue;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 13px;
    width: 50%;
}
.foo-con a:hover{
    color: black;
}
.fc-box{
    height:80%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: space-around;
    align-items: center;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px,
    rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    background: linear-gradient(180deg,rgb(255, 255, 255) 60%,rgb(255, 191, 62)
    40%);
    border-radius: 5px;
    margin-bottom: 4rem;
    border: .5px solid black;
}

.fc-box section{
    margin-top: -.5rem;
    text-align: center;
}
.fc-box section label{
    font-size: 13px;
}
.fc-form-con{
    background: rgb(232, 236, 239);
    width: 100%;
    height: auto;
}
.fc-gfrom{
    width: 100%;
    height: 2290px;
    margin-left: auto;
    margin-right: auto;
    border:none;
}

@media screen and (max-width:1163px){
    .fc-grid{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        justify-content: space-around;
        align-items: center;
        gap: 5%;
        margin-left: auto;
        margin-right: auto;
    }
    .fc-box{
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }
    
}
@media screen and (max-width:767px){
    .fc-grid{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        row-gap: 2rem;
        column-gap: -2%;
        margin-left: auto;
        margin-right: auto;
    }
    .fc-box{
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }
    
@media screen and (max-width:595px){
    .fc-grid{
        display: grid;
        grid-template-columns: 1fr;
        gap: .6rem;
        margin-left: auto;
        margin-right: auto;
    }
    .fc-box{
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }
}

}