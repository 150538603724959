.opn{
    width: 95%;
    height: auto;
    margin-left:auto ;
    margin-right:auto;
    padding: 3%;
    background-color: black;
    border: 1px solid white;
    color:white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    border: 1px solid white ;
    border-radius: 8px;
    margin-top: 2rem;
    margin-bottom: 3rem;
}
.opn header{
    text-transform: uppercase;
    word-spacing: .3rem;
    letter-spacing: .1rem;
    text-align: center;
}
.opn-cards img{
    height: 9rem;
    width: 13rem;
    margin: 0 2rem;
    border-radius: 2px;
}
.opn-cards{
    width: 100%;
    height: auto;
    background-color: rgb(240, 242, 228);
    border-radius: 8px;
    padding: 3%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;
}
.opn-box{
    background-color: aliceblue;
    height: 21rem;
    width:15rem;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px,
    rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    color: black;
    align-items: center;
    justify-content: space-around;
}
.opn-box:hover{
    width: 16rem;
    height: 22rem;
    transition: 40ms;
}
.opn-box h4{
    text-transform: uppercase;
}
.opn-box-explain{
    height: 30%;
    width: 90%;
}
.opn-box-explain p{
    font-size: 12px;
    margin-bottom: 2px;
}
.opn-box-explain p span{
    font-weight: 700;
    font-size: 13px;
}
.opn-box button{
    margin-bottom: 1.5rem;
    width: 8rem;
    height: 1.6rem;
    background-color: rgb(212, 165, 76);
    font-size: 10px;
    text-transform: uppercase;
    border-radius: 3px;
    border: none;
    font-weight: 600;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
.opn-box button:hover{
    background-color: rgb(101, 137, 79);
}

@media screen and (max-width:1163px){
    .opn{
        width: 95%;
        max-height: 100%;
        margin-top: 15rem;
    }
    .opn-cards{
        width: 100%;
        max-height: max-content;
        display: grid;
        grid-template-columns: repeat(2,1fr);
        row-gap: 2%;
        align-items: center;
        padding-bottom: 3rem;
    }
    .opn-box{
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (max-width:595px){
    .opn{
        width: 95%;
        max-height: 100%;
    }
    .opn-cards{
        width: 100%;
        max-height: max-content;
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 2%;
        align-items: center;
        padding-bottom: 8rem;
    }
    .opn-box{
        margin-left: auto;
        margin-right: auto;
    }
}

