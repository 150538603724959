.footer{
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    bottom: 0;
}
.section_padding{
    background: linear-gradient(rgb(69, 75, 109),black);
    width: 100%;
    height: 100%;
    padding:2rem;
}

.f-logo-name{
    display: flex;
    align-items: center ;
    gap: .5rem;
    margin-bottom: 1rem;
    
}
.f-logo-name h5{
    text-align: start;
    font-weight: 700;
    letter-spacing: 0.05rem;
}
.f-logo-name .logo{
    font-size: 16px;
}
.footer-links_div img{
    height: 4rem;
    width: 4rem;
}
.f-mail{
    font-size: 14px;
}
.footer-links{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    text-align: left;
    margin-bottom: 2rem;
}


.footer-links_div{
    width: 150px;
    margin:1rem;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    color: aliceblue;
}

.footer a{
    color: rgb(179, 167, 150);
    text-decoration: none;
    text-transform: uppercase;
}

.socialmedia{
    display: flex;
    flex-direction: row;
    gap: .5rem;
}

.footer-links_div h4{
    font-size: 18px;
    line-height: 17px;
    margin-bottom: 0.9rem;

}

.footer-links_div p{
    font-size: 14px;
    line-height: 15px;
    margin: 0.5rem 0;
    cursor: pointer;
}

.footer-below{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0.2rem;
}

.footer-below-links{
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.footer-below-links p{
    font-size: 14px;
    line-height: 15px;
    margin-left:2rem ;
    color: rgb(179, 167, 150);
    font-family: 600;

}

.footer hr{
    color: azure !important;
    width: 100%;
}
.footer-copyright p{
    font-size: 15px;
    line-height: 15px;
    color: rgb(179, 167, 150);

}
.footer-links_div a:hover{
    color: aliceblue;
    font-weight: 700;
}

.f-design{
    font-size: 12px;
    color: rgb(179, 167, 150);
    text-align: center;
    margin-top: .7rem;
}
.socialmedia span{
    color: rgb(179, 167, 150);
}
.f-social{
    cursor: pointer;
}
.socialmedia span:hover{
    color: aliceblue;
}

.footer-copyright p:hover{
    color: aliceblue;
}

.footer-below-links p:hover{
    color: aliceblue;
}


@media screen and (max-width: 850px){
    .sb_footer-heading h1{
        font-size:44px;
        line-height: 50px;
    }
}

@media screen and (max-width: 550px){
    .footer-heading h1{
        font-size:14px;
        line-height: 42px;
    }
    .footer-links div{
        margin: 1rem 0;
    }

    .footer-btn p{
        font-size: 14px;
        line-height: 20px;
    }

    .footer-below{
        flex-direction: column;
        justify-content: left;
    }

    .footer-below-links p{
        margin-left: 0rem;
        margin-top: 1rem;
    }
}

@media screen and (max-width:400px){
    .footer-heading h1{
        font-size: 27px;
        line-height: 38px;
    }
    
}



    



