.addp-wrapper{
    width: 100%;
    background-image: url(../home/home-bgi.png);
    background-size: cover;
    background-repeat: no-repeat;
    height: fit-content;
    padding-top: 2rem;
    padding-bottom: 2rem;
}
.addp-input{
    display: flex;
}
.addp-input-box{
    width: 43% ;
} 
.addp-form{
    box-shadow: rgba(242, 244, 245, 0.3) 0px 1px 2px 0px, rgba(248, 249, 250, 0.15) 0px 2px 6px 2px,
    rgba(244, 245, 246, 0.3) 0px 1px 2px 0px, rgba(250, 251, 252, 0.15) 0px 2px 6px 2px;
    margin-bottom: 2rem;
}

@media screen and (max-width:760px){
    .addp-input{
        display: block;
        color: inherit;      
    } 
    .addp-input-box{
        width: 100% ;
    }  
}