*,
*:after,
*:before {
    margin: 0;
    padding: 0;
}
.layout-body .sr-only {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;
}
.layout-body img {
    width: 100%;
    height: 10rem;
}

.layout-body{
    padding-top: .3rem;
    padding-bottom: 1rem;
    
}

 :root {
    --bg: hsl(0, 0%, 98%);
    --bg-offset: hsl(0, 0%, 100%);
    --text: hsl(200, 15%, 8%);
    --gray: hsl(0, 0%, 52%);
    --border: rgba(0, 0, 0, 0.1);
}
.layout-body ul,
.layout-body ol {
    list-style: none;
}
.layout-btn{
    list-style: none;
}
.layout-btn button{
    width: 100%;
    margin-top: 20px;
    height: 2.5rem;
    border: none;
    // background-color: rgb(239, 218, 167);
    background-color: aliceblue;
    border: .5px solid goldenrod;
    border-radius: 1px;
    letter-spacing: .5px;
}
.layout-btn button:hover{
    color: white;
    background-color: black;
    cursor: pointer;
}
.layout-body {
    background: var(--bg);
    font-family: sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    background-color: white;
    min-height: 30rem;
    
}

.layout-body h2 {
    height: 16px;
    margin-bottom: 2rem;
}
.layout-body .wrapper {
    width: 96%;
    max-width: 1140px;
    margin: 0 auto;
}
.layout-body .card-grid {
    margin: 2em 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 48px;
    
}
.layout-body .card {
    background-color: var(--bg-offset);
    padding: 0px;
    box-shadow: 0px 4px 8px var(--border);
    transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);

    &:hover {
        transform: scale(1.1);

        .card-content {
            h2 {
                display: block;
                -webkit-line-clamp: none;
                -webkit-box-orient: none;
                overflow: visible;
            }
        }
    }
}

.layout-body .card-image {
    max-height: 150px;
    overflow: hidden;
}
.layout-body .card-image img {
    margin-top: -13px;
    min-height: 10rem;
    width: 100%;
    object-fit: fit-content;
    object-position: center;
}
.layout-body .card-content {
    padding: 25px 15px;

    h2 {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        margin-bottom: 4rem;
    }
}
.layout-body .card-list {
    margin-top: 18px;

    li {
        color: var(--text);
        margin-top: 8px;

        span {
            color: var(--gray);
        }
    }
}

/* search input */
.layout-body .search-wrapper {
    margin: 48px 0;
    display: flex;
    justify-content: space-between;
    border: none;
}

$small : 425px;
$medium: 1144px;

.srch-price{
    margin-top: -2rem;
    width:60%;

    @media  screen and (max-width: $medium) {
       width: 95%;
       margin-top: 2rem;
       margin-left: auto;
       margin-right: auto;
    }
}

.layout-body .search-input {
    margin-top: 3rem;

    @media  screen and (max-width: $medium) {
        width: 100%;
        margin-top: -1rem;
        margin-left: auto;
        margin-right: auto;
     }
}

.srch-price-div{
    display: flex;
    justify-content:space-between;
    

    @media  screen and (max-width: $medium){
        flex-direction: column;
        gap: .5rem;
    }

}
.srch-min{
    margin-top:.5rem;
    width:180%;
    height: 3rem;
    padding: 5%;
    border: 1px solid var(--border);
    color: var(--gray);
    box-shadow: 0px 4px 6px var(--border);
    transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);

    @media  screen and (max-width: $medium){
        width: 100%;
        margin-top: .5rem;
    }
}

.srch-max{
    margin-top:.5rem;
    width:180%;
    margin-left: -80%;
    height: 3rem;
    padding: 5%;
    border: inherit;
    border: 1px solid var(--border);
    color: var(--gray);
    box-shadow: 0px 4px 6px var(--border);
    transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);

    @media  screen and (max-width: $medium){
        width: 100%;
        margin-top: .5rem;
        margin-left: 0;
    }
}

.srch-cmn{
    margin-left: -130%;
    
    @media  screen and (max-width: $medium){
        margin-left: 0;

    }

}



@media (max-width:1144px) {
    
     .srch-price h3{
        margin-bottom: 3rem;
     }
     .srch-price-div{
        display: flex;
        flex-direction: column;
     }
    .layout-body .search-wrapper {
        justify-content: start;
        flex-wrap: wrap;
        flex-direction: column;
    }
    .layout-body .select {
        margin-top: 3.5em;
    }
    .layout-body img {
        width: 100%;
        height: 100%;
    }
    
}

.layout-body .search-input {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0xNS44NTMgMTYuNTZjLTEuNjgzIDEuNTE3LTMuOTExIDIuNDQtNi4zNTMgMi40NC01LjI0MyAwLTkuNS00LjI1Ny05LjUtOS41czQuMjU3LTkuNSA5LjUtOS41IDkuNSA0LjI1NyA5LjUgOS41YzAgMi40NDItLjkyMyA0LjY3LTIuNDQgNi4zNTNsNy40NCA3LjQ0LS43MDcuNzA3LTcuNDQtNy40NHptLTYuMzUzLTE1LjU2YzQuNjkxIDAgOC41IDMuODA5IDguNSA4LjVzLTMuODA5IDguNS04LjUgOC41LTguNS0zLjgwOS04LjUtOC41IDMuODA5LTguNSA4LjUtOC41eiIvPjwvc3ZnPg==");
    background-color: var(--bg-offset);
    background-size: 16px 16px;
    background-position: left 10px center;
    background-repeat: no-repeat;
    padding: 1.4em 2em;
    padding-left: 2.7em;
    border: 1px solid var(--border);
    color: var(--gray);
    box-shadow: 0px 4px 6px var(--border);
    transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);

    &:hover {
        box-shadow: 0px 0px 0px var(--border);
    }
}

.layout-body .srch-price {
    background-color: var(--bg-offset);
    background-size: 16px 16px;
    background-position: left 10px center;
    background-repeat: no-repeat;
    padding: 1.4em 2em;
    padding-left: 2.7em;
    border: 1px solid var(--border);
    color: var(--gray);
    box-shadow: 0px 4px 6px var(--border);
    transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);

    &:hover {
        box-shadow: 0px 0px 0px var(--border);
    }
}
.buy--btn{
    padding: 1.5em 3.1em;
    border: none;
    border-radius: 7px;
    font-size: 1.2em;
    font-weight: 700;
    letter-spacing: 1.3px;
    color: #fff;
    background-color: #ff3f40;
    box-shadow: 2px 2px 25px -7px #4c4c4c;
    cursor: pointer;

    &:hover{
        background-color: black;
    }
}
/* select from moderncss.dev */
.layout-body select {
    // A reset of styles, including removing the default dropdown arrow
    appearance: none !important;
    // Additional resets for further consistency
    outline: none;
    background-color: var(--bg-offset);
    border-radius: 0.25em;
    border-width: 1px;
    border-style: solid;
    border-color: var(--border);
    padding: 1.4em 2em 1.4em 1em;
    margin: 0;
    margin-right: 1em;
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
    width: 100%;
    color: var(--gray);

}
.layout-body .select {
    min-width: 15ch;
    max-width: 30ch;
    cursor: pointer;
    line-height: 1.1;
    background-color: transparent;
    display: grid;
    grid-template-areas: "select";
    align-items: center;
    
    box-shadow: 0px 4px 6px var(--border);
    transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);

    &:hover {
        box-shadow: 0px 0px 0px var(--border);
    }

    &::after {
        content: "";
        display: block;
        width: 0.8em;
        height: 0.5em;
        background-color: var(--text);
        clip-path: polygon(100% 0%, 0 0%, 50% 100%);
        justify-self: end;
        margin-right: 1em;      
    }
}
.layout-body select,
.select:after {
    grid-area: select;
}
.layout-body select:focus + .focus {
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border: 2px solid var(--text);
    border-radius: inherit;

}