.signup-wrapper{
    background-image: url(../home/home-bgi.png);
    padding-top:1rem ;
    padding-bottom: 1rem;
    background-size: cover;
    height: fit-content;
}
.hide-asterisk {
    visibility: hidden;
  }

.signup-container{
    background-color: white;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    padding: .05rem;
    border: 2px solid black;
    margin-top: 2rem;
    margin-bottom: 2rem;
    border-radius: 6px;
    box-shadow: rgba(231, 233, 235, 0.3) 0px 1px 2px 0px, rgba(243, 244, 246, 0.15) 0px 2px 6px 2px,
    rgba(235, 238, 240, 0.3) 0px 1px 2px 0px, rgba(241, 244, 246, 0.15) 0px 2px 6px 2px;
}
@media screen and (max-width:1211px) {
    .signup-container{
        margin-top: 1rem;
        margin-bottom: 1rem;
        padding: 0;
        width: 60%;
        margin-left: auto;
        margin-right: auto;
        margin-top: .5rem;
    }
    
}
@media screen and (max-width:728px) {
    .signup-container{
        width: 70%;
        margin-left: auto;
        margin-right: auto;
    }
    
}
@media screen and (max-width:523px){
    .signup-container{
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }
}