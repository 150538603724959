@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
body{
    font-family: "Poppins",sans-serif;
    background-color: #fff;
}