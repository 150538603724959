.st1{
    background-color: white;
    width: 100%;
    height: 38rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    padding: 5%;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.st1 h1{
    color: rgb(79, 79, 120);
    text-transform: uppercase;
    text-align: center;
}
.st1 h2{
    text-align: center;
    text-transform: capitalize;
    color: rgb(95, 96, 56);
}
.st1 h3{
    color: rgb(160, 42, 42);
}
.st1 ol{
    color: black;
    font-weight: 600;
}
.st1 li{
    margin-bottom: .5rem;
}
.st1 button{
    padding: 1.5em 3.1em;
    border: none;
    border-radius: 7px;
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: 1.3px;
    color: #fff;
    background-color: #ff3f40;
    box-shadow: 2px 2px 25px -7px #4c4c4c;
    cursor: pointer;
    text-transform: capitalize;
}
.st1 button:hover{
    background-color: goldenrod;
    color: black;
}
@media screen  and (max-width:500px){
    .st1{
        padding: 8%;
        height: 40rem;
    }
    .st1 button{ padding: 1.2em ;
        margin-bottom: 2rem;
    }
}
