.nav-header{
    width: 100%;
    height: 8rem ;
    background-color: rgb(0, 0, 0);
    position: fixed ;
    top: 0;
    left: 0;
    background-size: cover;
    z-index: 3;
}
.abv{
    margin-top: 1rem;
    margin-right: 1rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: aliceblue;
    gap:1rem
}
.abv .cal{
    display: flex;
    font-size: x-small;
}
.abv .cal a{
    text-decoration: none;
}
.abv .cal a:hover{
    font-size: small;
    text-decoration: underline;
}
.header-container{
    max-width: 80%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: -2rem;
}
.logo-name{
    display: flex;
    gap: .5rem;
    margin-top: -1.5rem;
    margin-left: -7rem;
    color:rgba(240, 248, 255,.8);
    letter-spacing: .05rem;
    margin-bottom: 1rem;
}
.logo-name h2{
    align-self: baseline;
    color: aliceblue;
}
#paymentForm:hover{
    width: 12rem;
    content: icon;
}
.header-logo img{
    max-width: 4rem;
    max-height: 4rem;
    margin-top: 1rem;
}
.header-nav .header-list{
    display: flex;
    align-items: center;
    gap: 3rem;
    list-style: none;
    margin-left: 15rem;
    margin-right: 1rem;
    margin-top:1.2rem ;
}
.header-nav .header-list a{
    text-decoration: none;
    color: rgb(239, 183, 43);
    text-transform: capitalize;
    transition: .5s;
    font-size: 14px;
}
.header-nav .header-list a:hover{
    color: aliceblue;
}
.login-register Button{
    text-decoration: none;
    color: rgb(17, 17, 18);
    margin-right: -7rem;
    background-color: rgb(239, 183, 43);
    padding: .5rem 1rem;
    font-size: 14px;
    border-radius: 5px;
    transition: .5s;
    font-weight: 700;
    text-transform: none;
}
.logo-name h2{
    font-size: 28px;
}
.login-register Button:hover{
    background-color: aliceblue;
}
.dot-button{
   position: absolute;
   right: 3rem;
   padding: 1rem;
   display: none;
}
.dot-button button{
    background-color: rgb(239, 183, 43);
    color: black;
    padding: .3rem;
}
.abv-div{
    display: flex;
    gap: .3rem;
}
.nav-header .small {
    position: absolute;
    top:8rem;
    left:0;
    width:100%;
    background-color:rgb(235, 193, 69) ;
    color: black;
    padding: 20px;
    z-index: 2000000000 !important;
    text-align: start;
}
.nav-header .small Button{
    background-color: transparent;
    box-shadow: none;
    margin-left: -1rem;
    text-decoration: none;
    text-transform: none;
    color: black;
    font-size: 16px;
    z-index: 20;
}
.nav-header .smallList{
    opacity: 1;
    top:11.5rem;
    z-index: 2000000000  !important;
    font-weight: 700 ;
    
}
header a{
    display: inline-block;
    margin-bottom: 20px;
    color: rgb(8, 8, 8);
}
.profile-btn{
    color: balck;
    margin-right: -5rem;
    display: flex;
    align-items: center;
    background-color: goldenrod;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
}
.prf{
    color: balck;
    margin-right: -5rem;
    display: flex;
    align-items: center;
    background-color: goldenrod;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
}

@media screen and (max-width:1163px){
    .nav-header{
        padding: 0;
        width: 100%;      
    }
    .header-container{
        max-width: 90%;
        padding-top: .5rem;
    }
    .logo-name{
        margin: 0;
        margin-top: -1.5rem;
    }
    .logo-name h2{
        font-size: 24px;
    }
    .header-nav .header-list{
        display: none;
    }
    .dot-button{
        display:contents;
        position: fixed;
    }
    .login-register{
        display: none;
    }
    .nav-header ul{
        list-style: none;
    }
    .nav-header a{
        text-decoration: none;
        text-transform: capitalize;
    }
    .profile-btn{
        display: none;
    }
    
}


@media screen and (max-width:451px){
    #paymentForm:hover{
        width:10rem;
    }
    .abv{
        margin-bottom: 1rem;
    }
    .abv-div{
        flex-direction: column;
        gap: .1rem;
    }
    .abv .cal{
        margin-top: -1rem;
        font-size: 9px;
    }

    .logo-name h2{
        font-size: 18px;
    }
    .logo-name img{
        max-width: 3rem;
        height: 3rem;
    }
    
}

