body{
    background-image: url(./home-bgi.png);
    background-position: fixed;
    background-repeat: no-repeat;
    background-size: 130%;
    width: 100%;
    height: 40rem;
    margin-top: 8rem;
}
.section-one img{
    background-color: rgba(247, 243, 243, 0.8);
    width: 45%;
    padding: .6rem;
    margin-top: 3rem;
}
.section-one video{
    background-color: rgba(0, 0, 0,.8);
    color: white;
    padding: 2rem;
    margin-top: 3rem;
    margin-left: 5%;
    width: 23%;
}
.moto{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 90%;
    margin-top: 2rem;
    padding: 2%;
    margin-left: auto;
    margin-right: auto;
}
.moto .vdo1{
    width: 25%;
}
.moto .vdo2{
    width:40%;
}
.vdo3{
    width: 45%;
    margin-top: 2rem;
}
.home-service h2{
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size:xxx-large;
    text-align: center;
    opacity: .8;
    margin-top: 3rem;
}
.home-service{
    width: 100%;
    height: fit-content;
}
.home-service h3{
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif ;
    font-size: large;
    opacity: .6;
    text-transform: uppercase;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 3rem;
}

@media screen and (max-width:1163px){
    body{
        background-size: cover;
    }
    .section-one video{
        padding: 1rem;
        width: 30%;
    }
    .moto{
        flex-direction: column;
        gap: 2rem;
    }
    .moto .vdo1{
        width: 60%;
    }
    .moto .vdo2{
        width: 90%;
    }
    .vdo3{
        width:90%;
    }
}
@media (min-width:760px) and  (max-width:1164px){
    .home-service{
        margin-bottom: 15rem;
    }
    
}
@media screen and (max-width:570px){
    .section-one{
        margin-left: auto;
        margin-right: auto;
    }
    .section-one video{
        width: 40%;
    }
    .vdo3{
        margin-top: 1rem;
        margin-bottom: -1rem;
    }
}