.r-body{
  width:100%;
  background-image: url('./blur2.jpg');
  background-size: cover;
  padding:2% 5% 5% 5%;
  margin-top: 3rem;
}
.r-heading{
  text-align: center;
  color: aliceblue;
  margin-bottom:  1rem;
  text-transform: uppercase;
  word-spacing: .2rem;
  letter-spacing: .1rem;
  font-weight: 700;
}
.review-body{
  display: flex;
  height: auto;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0,0.7);
  position: relative;
  box-shadow: rgba(243, 246, 247, 0.3) 0px 1px 2px 0px, rgba(239, 241, 243, 0.15) 0px 2px 6px 2px,
    rgba(253, 254, 255, 0.3) 0px 1px 2px 0px, rgba(244, 246, 247, 0.15) 0px 2px 6px 2px;   
    border: 1px solid white;
  border-radius: 6px;
  padding-bottom: 5rem;
}
.review-body::before{
  position: absolute;
  width: 100%;
  z-index: -1;
  height: 100%;
}

/* ::selection{
  in this section we style the section which is done by user to copy text
  background:	#f0c9cb;
  color: #471f1f;
} */
.r-container{
  /* set max with if needed  eg:- 1000px*/
  overflow: hidden;
  padding: 50px 30px 0 0;
  margin-left: auto;
  margin-right: auto;
}

.r-container .review-card{
  display: flex;
  justify-content: space-evenly;
 
  width: 200%;
  transition: 0.7s;
}
#r-two:checked ~ .review-card{
  margin-left: -100%;
}
.r-box2{
  transform: translateX(3%);
}
.r-container .review-card .r-card-box{
  width: 46%;
  display: flex;
  flex-wrap: wrap;
  margin: 0 ;
  justify-content: space-between;
 
 
}
.review-card .r-card-box .r-card{
  width: calc(100% / 3 - 2rem);
  background: aliceblue;
  border-radius: 6px;
  padding: 10px;
  transition: all 0.4s ease;
  box-shadow: rgba(243, 246, 247, 0.3) 0px 1px 2px 0px, rgba(239, 241, 243, 0.15) 0px 2px 6px 2px,
    rgba(253, 254, 255, 0.3) 0px 1px 2px 0px, rgba(244, 246, 247, 0.15) 0px 2px 6px 2px; 
}

.r-card-box .r-card .r-content{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.r-card-box .r-card .r-content .r-img{
  height: 250px;
  width: 100%;
  border-radius: 5px;
  padding: 3px;
   /* background color of image box */
  margin-bottom: 14px;
}
.r-card .r-content .r-img img{
  height: 100%;
  width: 100%;
  border: 2px solid #b5b8b5;
  /* background color of image  */
  border-radius: 5px;
  object-fit: cover;
}
.r-card .r-content h4{
  font-size: 16px;
  font-weight: 600;
}
.r-card .r-content p{
  text-transform: capitalize;
  font-size: 14px;
  color: #048091;
}

/* styling of name section */

 .r-container .r-button{
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px;
}
.r-button label{
  height: 15px;
  width: 15px;
  border-radius: 20px;
  background: #fff;
  margin: 0 4px;
  cursor: pointer;
  transition: all 0.5s ease;
}
#r-one{
  position: absolute;
  bottom: 5%;
  left: 48%;
  cursor: pointer;
}
#r-two{
  position: absolute;
  bottom: 5%;
  left: 50%;
  cursor: pointer;
}
.r-button label.active{
  width: 15px;
}
#r-one:checked ~ .button .one{
  width: 25px;
}
#r-one:checked ~ .button .two{
  width: 15px;
}
#r-two:checked ~ .button .one{
  width: 15px;
}
#r-two:checked ~ .button .two{
  width: 25px;
}


@media (max-width: 768px) {
  .review-card .r-card-box .r-card{
    margin: 20px 0 10px 0;
    width: calc(100% / 2 - 10px);
  }
  #r-one{
    left:47%;
  }
}
@media (max-width: 600px) {
  .review-card .r-card-box .r-card{
    /* margin: 20px 0 10px 0; */
    width: 100%;
    margin: 1rem;
  }
  .r-box1{
    transform: translateX(2%);
  }
  .r-box2{
    transform: translateX(6%);
  }
  #r-one{
    left:45%;
  }
}
