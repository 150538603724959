.hero{
  padding-bottom: 2rem;
  padding-top: 2rem;
  margin-right: auto;
  border-radius: 3px;
  background-color: black;
  width:80%;
  border:solid 1px white;
  margin-left: auto;
  margin-top: 5rem;
  box-shadow: 3px 3px 1px 0px rgb(78, 77, 77);
  margin-bottom: 5rem;
}
.container h2{
  color: aliceblue;
  text-align: center;
  margin-top: -.5rem;
  text-transform:uppercase ;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  word-spacing: .2rem;
  letter-spacing: .1rem;
}
.buy__btn{
    padding: 1em 2.5em;
    border: none;
    border-radius: 7px;
    font-size: 1em;
    font-weight: 700;
    letter-spacing: 1.3px;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.8);
    box-shadow: 2px 2px 25px -7px #4c4c4c;
    cursor: pointer;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
    text-align: center;
   
}

.container p{
  color: aliceblue;   
  text-align: center;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
.hero .flex{
  background-color: rgb(240, 242, 228);
  max-width: 90%;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  height: 7rem;
  border-radius: 5px;
  margin-top: 2rem;
  font-family: 'Times New Roman', Times, serif;
  margin-left: auto;
  margin-right:auto;
  box-shadow: 3px 3px 1px 0px rgb(78, 77, 77) inset;
}
.box label{
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 15px;
}
.box select{
  height: 1.8rem;
  width: 12rem;
  padding: 3px 3px;
  margin-top: .3rem;
}
.hero button{
  padding-top:.5rem ;
  background-color: transparent;
  text-decoration: none;
  border: none;
}
.search-button{
  margin-top: 1rem;
  background: none;
}
.hero button:hover{
  color: rgb(33, 33, 249);
}
.mobile-search-btn{
  display: none;
}

@media screen and (max-width:1163px){
    .hero{
      max-width: 80% ;
    }
    .container {
      font-size: 14px;
    }
    .hero .flex{
      display: flex;
      max-width: 90%;
      height: auto;
      padding: 1rem;
      margin-top: 2rem;
      flex-direction: column;
    }
    .hero select{
      margin-bottom: .5rem;
    }
    .hero button{
      display: none;
    }
    .mobile-search-btn{
      display: block;
      text-align: center;
      margin-top:1rem ;
      background-color: rgb(235, 193, 69);
      color: black;
      font-weight: 700;
      border-radius: 5px;
      height: 2.5rem;
      width: 8rem;
      padding-top: .8rem;
     }
  }
